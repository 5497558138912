import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'

import { capitalizeFirstChar } from 'utils/functions'

import SEO from 'components/SEO'
import VideoCard from 'components/VideoCard'
import PartnersLinks from 'components/PartnersLinks'
import Banner from 'components/Banner'

import styles from './Category.module.scss'

const CategoryPage = ({ data }) => {
  const { category, videos, site: { siteMetadata } } = data

  const breadcrumb = useMemo(() => ({
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${siteMetadata.siteUrl}/categories/`,
          "name": "Categories"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${siteMetadata.siteUrl}/categories/${category.slug}/`,
          "name": capitalizeFirstChar(category.name)
        }
      },
    ]
  }), [category.name])
  
  return (
    <>
    <SEO
      breadcrumb={breadcrumb}
      title={`${category.seoTitle} | SloppyDeep.com`}
      description={category.seoDescription}
      keywords={category.seoKeywords.join(', ')}
      imagePath={category.listedImage.publicURL}
      imageAlt={category.listedImageAlt}
      pagePath={`/categories/${category.slug}/`}
      robots='index, follow'
    />
    <Helmet>
      { /* prefetch for thumbnails */}
      <link rel='preconnect dns-prefetch' href='https://galleryn0.awemwh.com' crossorigin />
    </Helmet>
    <div className={styles.header}>
      <Img fluid={{ ...category.coverImage.childImageSharp.fluid, aspectRatio: 16/9 }} alt={category.coverImageAlt} className={styles.headerImg} />
      <h1 className={styles.headerTitle}>{category.name}</h1>
      <h2 className={styles.headerSubtitle}>category</h2>
    </div>
    <div className={`screen-container`}>
      <div className={`${styles.videosBlock} video-cards-container`}>
        <VideoList videos={videos.nodes} />
      </div>

      {category.partnersLinks.length > 0 &&
        <>
          <div className={styles.titleBlock}>Sloppy Partners</div>
          <div className={styles.partnersLinksBlock}>
            <PartnersLinks links={category.partnersLinks} />
          </div>
        </>
      }

    </div>
    </>
  )
}

const VideoList = ({ videos }) => {

  const firstBannerIndex = 3 + Math.floor(Math.random() * 4) // [3-6]
  const bannerFrequency = 10

  let componentList = []

  for (let i = 0; i < videos.length; i++) {
    const shouldPushBanner = (firstBannerIndex + i) % bannerFrequency === 0

    if (shouldPushBanner) {
      componentList.push(i < 10
        ? <Banner.Livejasmin key={`banner-${i}`} />
        : <Banner.Stripcash key={`banner-${i}`} />)
    }

    componentList.push(<VideoCard key={videos[i].slug} data={videos[i]} />)
  }

  return componentList
}

export const query = graphql`
  query CategoryPageQuery($id: String!) {

    category: sloppyDeepCategory(id: {eq: $id}) {
      name
      slug
      description
      seoTitle
      seoDescription
      seoKeywords
      partnersLinks
      listedImage {
        publicURL
      }
      listedImageAlt
      coverImage {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      coverImageAlt
    }

    videos: allSloppyDeepVideo(
      filter: {categories: {elemMatch: {id: {eq: $id}}}},
      sort: {order: DESC, fields: publishDate}
    ) {
      nodes {
        slug
        title
        views
        publishDate
        duration
        isHd
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 380, maxHeight: 214, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        thumbnailAlt
        preview
        model {
          slug
          primaryAka {
            nickname
          }
          profilePicture {
            childImageSharp {
              fluid(maxWidth: 50, maxHeight: 50, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  
  }
`

export default CategoryPage
